import { graphql } from 'gatsby';
import React from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import CareersHero from '~/components/Hero/CareersHero';
import Layout from '~/components/Layout';
import { CareersPageQuery } from '../graphql-types';

interface CareersProps {
  data: CareersPageQuery;
}

const CareersFC: React.FunctionComponent<CareersProps> = ({ data }) => {
  const {
    careersPage: { careersHero },
    layouts,
  } = data.wpPage;
  return (
    <Layout>
      <SEO title="Careers" />

      <main>
        <CareersHero hero={careersHero} />
        <FlexibleContent pageName="careers" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default CareersFC;

export const query = graphql`
  query CareersPage($id: String!) {
    wpPage(id: { eq: $id }) {
      careersPage {
        careersHero {
          subheading
          heading
          content
          button {
            url
            title
          }
          leftImageTop {
            ...Image
          }
          leftImageBottom {
            ...Image
          }
          rightImageTop {
            ...Image
          }
          rightImageBottom {
            ...Image
          }
        }
      }

      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_CareersValues {
            subheading
            heading
            value {
              heading
              content
            }
          }
          ... on WpPage_Layouts_Layouts_Vacancies {
            heading
            content
            noVacanciesNotice
            link {
              url
              title
            }
          }
          ... on WpPage_Layouts_Layouts_Perks {
            heading
            content
            perk {
              heading
              description
              icon {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_ExtendedPerks {
            heading
            perk {
              content
            }
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
        }
      }
    }
  }
`;
